import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { JoinUs, FAQCard } from '../components';
import { Title, Line } from '../components/styled';
import theme from '../styles/theme';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { useStateValue } from '../state';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GrayLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 1px;
  width: 80%;
  margin: 60px 0 0px 0;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
`;

const FAQPage = () => {
  const [
    { faq: { faqs }, },
    dispatch,
  ] = useStateValue();
  
  useFetch({
    url: urls.siteContent.faq,
    method: method.GET,
    condition: () => (!faqs),
    callback: data => dispatch({
      type: 'setFaqs',
      newFaqs: data,
    })
  });

  return (
    <Wrapper>
      <Title>PERGUNTAS FREQUENTES</Title>
      <Line />
      <FAQCard questions={faqs} />
      <GrayLine />
      <StyledContainer>
        <JoinUs />
      </StyledContainer>
    </Wrapper>
  );
};

export default FAQPage;

export const pageQuery = graphql`
  query FAQQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
